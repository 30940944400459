.UnderConstruction {
   text-align: center;
   padding: 3em;
   h1 {
      color: var(--green);
   }
   svg {
      padding: 5em;
      width: 30%;
      g:nth-child(1) {
         position: fixed;
         animation: rotate 2s linear infinite;
         transform-origin: center;
         transform-box: fill-box;
      }
      g:nth-child(2) {
         position: fixed;
         animation: rotateBack 1.5s linear infinite;
         transform-origin: center;
         transform-box: fill-box;
      }
      g:nth-child(3) {
         position: fixed;
         animation: rotateBack 10s linear infinite;
         transform-origin: center;
         transform-box: fill-box;
         opacity: 0.5;
         path {
            fill: var(--orange);
         }
      }
   }
   .cls-1 {
      fill: var(--green);
   }
   h4 {
      padding: 1em;
      color: var(--black);
      font-weight: normal;
      a {
         color: var(--orange);
      }
   }
}
@keyframes rotate {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
@keyframes rotateBack {
   0% {
      transform: rotate(360deg);
   }
   100% {
      transform: rotate(0deg);
   }
}
@media only screen and (max-width: 1500px) {
   .UnderConstruction {
      svg {
         padding: 6em;
         width: 70%;
      }
   }
}
@media only screen and (max-width: 800px) {
   .UnderConstruction {
      padding: 3em 1em;
   }
}
@media only screen and (max-width: 1500px) {
   .UnderConstruction {
      svg {
         padding: 3em;
         width: 100%;
      }
   }
}
