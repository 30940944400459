.header {
   box-shadow: 1px 1px 40px 25px rgba(0, 0, 0, 0.025);
   margin: 1em;
   border-radius: 15px;
   .top {
      display: flex;
      justify-content: space-between;
      margin: 1em;
      padding: 1em;
      align-items: center;
      border-bottom: solid 1px var(--orange);
      .burgerMenu {
         display: none;
         max-width: 10%;
         svg {
            width: 100%;
            cursor: pointer;
         }
         .cls-1 {
            fill: none;
            stroke: var(--green);
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2em;
         }
         .sideMenu {
            margin: 0 -2em;
            width: 30%;
            height: 100%;
            background-color: var(--green);
            position: fixed;
            top: 0;
            // padding: 1em;
            z-index: 1;
            overflow-y: auto;
            // display: none;
            .top {
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin: 1em 0;
               img {
                  width: 40%;
               }
               .close {
                  width: 10%;
                  cursor: pointer;
                  .cls-1 {
                     fill: none;
                     stroke: var(--white);
                     stroke-linecap: round;
                     stroke-linejoin: round;
                     stroke-width: 2em;
                  }
               }
            }
            hr {
               border: none;
               border-bottom: solid 2px var(--orange);
            }
            nav {
               a {
                  color: var(--white);
                  text-decoration: none;
               }
               h3 {
                  margin: 1em;
               }
               p {
                  margin: 0.5em;
               }
               ul {
                  margin: 1em 3em;
                  list-style: none;
                  background-color: var(--white);
                  border-radius: 0px 10px 10px 10px;
                  li {
                     // padding: 1em;
                     border-bottom: solid 1px var(--green);
                     // padding: .5em 0em;
                  }
                  h3 {
                     margin: 0 0;
                     padding: 0.5em;
                  }
                  a {
                     text-decoration: none;
                     color: var(--orange);
                  }
               }
            }
         }
      }
      .socials {
         // align-self: center;
         // margin: 0 2em;
         display: flex;
         align-items: center;
         width: 10%;
         svg {
            width: 65%;
            fill: var(--orange);
         }
      }
      .logo {
         align-self: center;
         width: 15%;
         img {
            width: 100%;
         }
      }
      .cta {
         cursor: pointer;
         background-color: var(--green);
         color: var(--white);
         border-radius: 20px;
         text-align: center;
         padding: 1em;
         text-decoration: none;
         svg {
            // text-align: center;
            stroke: var(--white);
            width: 100%;
            display: none;
         }
      }
   }
   .menu {
      display: flex;
      justify-content: space-around;
      padding: 1em;
      margin: 0 1em;
      a {
         text-decoration: none;
         color: var(--black);
      }
      ul {
         list-style: none;
         position: absolute;
         background-color: var(--green);
         border-radius: 0px 10px 10px 10px;
         box-shadow: 5px 1px 50px 8px rgba(0, 0, 0, 0.1);
         margin: 1em;
         li {
            border-bottom: solid 1px var(--white);
            margin: 1em;
            a {
               margin: 1em;
               color: var(--white);
            }
         }
      }
   }
}
@media only screen and (max-width: 1500px) {
   .header {
      .top {
         .socials {
            width: 15%;
            // display: none;
         }
      }
   }
}
@media only screen and (max-width: 1400px) {
   .header {
      .burgerMenu {
         .sideMenu {
            width: 40%;
         }
      }
   }
}
@media only screen and (max-width: 1200px) {
   .header {
      .top {
         margin: 0 0;
         border: none;
         .burgerMenu {
            display: block;
            .sideMenu {
               width: 60%;
            }
         }
         .socials {
            display: none;
         }
      }
      .menu {
         display: none;
      }
   }
}
@media only screen and (max-width: 900px) {
   .header {
      .top {
         .logo {
            width: 30%;
         }
         .cta {
            width: 20%;
         }
      }
   }
}
@media only screen and (max-width: 700px) {
   .header {
      .top {
         .burgerMenu {
            .sideMenu {
               width: 80%;
               nav {
                  ul {
                     margin: 1em 1.5em;
                  }
               }
            }
         }
         .cta {
            border-radius: 10px;
            width: 10%;
            p {
               display: none;
            }
            svg {
               display: block;
            }
         }
      }
   }
}
@media only screen and (max-width: 500px) {
   .header {
      .top {
         .burgerMenu {
            width: 80%;
         }
         // .logo {
         //    width: 100%;
         // }
         .cta {
            // width: 22%;
            padding: 0.5em;
         }
      }
   }
}
@media only screen and (max-width: 400px) {
   .header {
      .top {
         .burgerMenu {
            max-width: 15%;
            .sideMenu {
               width: 90%;
            }
         }
         .logo{
            width: 40%;
         }
         .cta{
            width: 15%;
         }
      }
   }
}
