:root {
   --white: #fff;
   --black: #1c1c1c;
   --green: #48cd2d;
   --orange: #ec5923;
}

* {
   box-sizing: border-box;
   margin: 0 0;
   padding: 0 0;
   font-family: Arial, Helvetica, sans-serif;
}

/* Base font size */

html {
   font-size: 100%;
}

/* Desktop styles */
@media (min-width: 1024px) {
   h1 {
      font-size: 48px;
   }
   h2 {
      font-size: 36px;
   }
   h3 {
      font-size: 30px;
   }
   h4 {
      font-size: 24px;
   }
   h5 {
      font-size: 20px;
   }
   h6 {
      font-size: 18px;
   }
   p {
      font-size: 18px;
   }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
   h1 {
      font-size: 40px;
   }
   h2 {
      font-size: 32px;
   }
   h3 {
      font-size: 28px;
   }
   h4 {
      font-size: 22px;
   }
   h5 {
      font-size: 18px;
   }
   h6 {
      font-size: 16px;
   }
   p {
      font-size: 16px;
   }
}

/* Mobile styles */
@media (max-width: 767px) {
   h1 {
      font-size: 32px;
   }
   h2 {
      font-size: 28px;
   }
   h3 {
      font-size: 24px;
   }
   h4 {
      font-size: 20px;
   }
   h5 {
      font-size: 18px;
   }
   h6 {
      font-size: 16px;
   }
   p {
      font-size: 16px;
   }
}
