.header {
  box-shadow: 1px 1px 40px 25px rgba(0, 0, 0, 0.025);
  margin: 1em;
  border-radius: 15px;
}
.header .top {
  display: flex;
  justify-content: space-between;
  margin: 1em;
  padding: 1em;
  align-items: center;
  border-bottom: solid 1px var(--orange);
}
.header .top .burgerMenu {
  display: none;
  max-width: 10%;
}
.header .top .burgerMenu svg {
  width: 100%;
  cursor: pointer;
}
.header .top .burgerMenu .cls-1 {
  fill: none;
  stroke: var(--green);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2em;
}
.header .top .burgerMenu .sideMenu {
  margin: 0 -2em;
  width: 30%;
  height: 100%;
  background-color: var(--green);
  position: fixed;
  top: 0;
  z-index: 1;
  overflow-y: auto;
}
.header .top .burgerMenu .sideMenu .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
}
.header .top .burgerMenu .sideMenu .top img {
  width: 40%;
}
.header .top .burgerMenu .sideMenu .top .close {
  width: 10%;
  cursor: pointer;
}
.header .top .burgerMenu .sideMenu .top .close .cls-1 {
  fill: none;
  stroke: var(--white);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2em;
}
.header .top .burgerMenu .sideMenu hr {
  border: none;
  border-bottom: solid 2px var(--orange);
}
.header .top .burgerMenu .sideMenu nav a {
  color: var(--white);
  text-decoration: none;
}
.header .top .burgerMenu .sideMenu nav h3 {
  margin: 1em;
}
.header .top .burgerMenu .sideMenu nav p {
  margin: 0.5em;
}
.header .top .burgerMenu .sideMenu nav ul {
  margin: 1em 3em;
  list-style: none;
  background-color: var(--white);
  border-radius: 0px 10px 10px 10px;
}
.header .top .burgerMenu .sideMenu nav ul li {
  border-bottom: solid 1px var(--green);
}
.header .top .burgerMenu .sideMenu nav ul h3 {
  margin: 0 0;
  padding: 0.5em;
}
.header .top .burgerMenu .sideMenu nav ul a {
  text-decoration: none;
  color: var(--orange);
}
.header .top .socials {
  display: flex;
  align-items: center;
  width: 10%;
}
.header .top .socials svg {
  width: 65%;
  fill: var(--orange);
}
.header .top .logo {
  align-self: center;
  width: 15%;
}
.header .top .logo img {
  width: 100%;
}
.header .top .cta {
  cursor: pointer;
  background-color: var(--green);
  color: var(--white);
  border-radius: 20px;
  text-align: center;
  padding: 1em;
  text-decoration: none;
}
.header .top .cta svg {
  stroke: var(--white);
  width: 100%;
  display: none;
}
.header .menu {
  display: flex;
  justify-content: space-around;
  padding: 1em;
  margin: 0 1em;
}
.header .menu a {
  text-decoration: none;
  color: var(--black);
}
.header .menu ul {
  list-style: none;
  position: absolute;
  background-color: var(--green);
  border-radius: 0px 10px 10px 10px;
  box-shadow: 5px 1px 50px 8px rgba(0, 0, 0, 0.1);
  margin: 1em;
}
.header .menu ul li {
  border-bottom: solid 1px var(--white);
  margin: 1em;
}
.header .menu ul li a {
  margin: 1em;
  color: var(--white);
}

@media only screen and (max-width: 1500px) {
  .header .top .socials {
    width: 15%;
  }
}
@media only screen and (max-width: 1400px) {
  .header .burgerMenu .sideMenu {
    width: 40%;
  }
}
@media only screen and (max-width: 1200px) {
  .header .top {
    margin: 0 0;
    border: none;
  }
  .header .top .burgerMenu {
    display: block;
  }
  .header .top .burgerMenu .sideMenu {
    width: 60%;
  }
  .header .top .socials {
    display: none;
  }
  .header .menu {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .header .top .logo {
    width: 30%;
  }
  .header .top .cta {
    width: 20%;
  }
}
@media only screen and (max-width: 700px) {
  .header .top .burgerMenu .sideMenu {
    width: 80%;
  }
  .header .top .burgerMenu .sideMenu nav ul {
    margin: 1em 1.5em;
  }
  .header .top .cta {
    border-radius: 10px;
    width: 10%;
  }
  .header .top .cta p {
    display: none;
  }
  .header .top .cta svg {
    display: block;
  }
}
@media only screen and (max-width: 500px) {
  .header .top .burgerMenu {
    width: 80%;
  }
  .header .top .cta {
    padding: 0.5em;
  }
}
@media only screen and (max-width: 400px) {
  .header .top .burgerMenu {
    max-width: 15%;
  }
  .header .top .burgerMenu .sideMenu {
    width: 90%;
  }
  .header .top .logo {
    width: 40%;
  }
  .header .top .cta {
    width: 15%;
  }
}/*# sourceMappingURL=Header.css.map */